(function(){
var createModuleFactory = function createModuleFactory(factory) {
  var module
  return function (parent) {
    if (!module) {
      module = { exports: {}, parent: parent }
      factory(module, module.exports)
    }
    return module.exports
  }
};
